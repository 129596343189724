/* The slow way */
.card-primary {
    background-color: transparent;
    margin: 10px;
    padding: 0px;
    border-radius: 4px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease-in-out;
}

.card-primary.dark{
    background-color: transparent;
    color: black;
}

/* Transition to a bigger shadow on hover */
.card-primary:hover {
box-shadow: rgba(0, 0, 0, 0.5) 0px 7px 29px 0px;
cursor: pointer;
}

/* The fast way */
.card-primary_fast {
box-shadow: 0 1px 2px rgba(0,0,0);
}

/* Pre-render the bigger shadow, but hide it */
.card-primary_fast::after {
box-shadow: 0 5px 15px rgba(0,0,0);
opacity: 0;
transition: opacity 0.3s ease-in-out;
}

/* Transition to showing the bigger shadow on hover */
.card-primary_fast:hover::after {
opacity: 1;
}

.card-primary img{
    max-width: 300px;
    max-height: 300px;
    padding: 70px;
}

.card-primary p{
    /* background-color: white; */
    display: flex;
    justify-content: center;
    width: 100%;
    color: #ffdd00;
    padding: 15px;
    align-items: center;
}