/*div.loading {
    position: fixed;
    z-index: 500;
    padding-top: 100px;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.589);
}
 
div.loading div{
    display: flex;
    width: 3.5em;
    height: 3.5em;
    border: 3px solid transparent;
    border-top-color: #ffdd00;
    border-bottom-color: #ffdd00;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
}
  
div.loading div:before {
    content: "";
    display: block;
    margin: auto;
    width: 0.75em;
    height: 0.75em;
    border: 3px solid #ff0000;
    border-radius: 50%;
    animation: pulse 1s alternate ease-in-out infinite;
}

@keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulse {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
   */

   div.loading {
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.596);
}

div.loading div img{
  width: 150px !important;
  height: 150px;
  background-size: contain;
}