.table-outter {
    /* overflow-x: auto;
    overflow-y: hidden; */
    border: black;
    border-radius: 15px;
    margin: 2px;
    padding: 0px !important;
    width: 100%;
}

.app-table{table-layout: fixed;position: relative; margin: 0px auto 0px auto;width: 100%;overflow: visible !important;}
.app-table thead{width: 100%;border-top-left-radius: 15px; border-top-right-radius: 15px;}
.app-table tbody{width: 100%;overflow: visible !important;}
/* .app-table table{table-layout: fixed;position: relative; margin: 0px auto 0px auto;} */
/* table thead th { position: sticky; top: 0; background: rgb(139, 139, 139);} */
/* .app-table th{ } */
.app-table th{
    padding: 10px 7px;
    font-size: 15px;
    flex-wrap: nowrap;
    color:black;
    border: .5px solid #ffdd00;
    background:#ffdd00;
    min-height: 35px;
    top: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    font-size: 13px;
    /* border: 1px solid black; */
    overflow-wrap: break-word;
}

.app-table td{
    padding: 0px 10px;
    text-align: center;
    border: .5px solid gray;
    min-height: 35px;
    height: max-content;
    overflow-x: auto;
    overflow-y: hidden;
    overflow-wrap: break-word;
    padding-top: 4px;
    padding-bottom: 4px;
    overflow: visible !important;
    font-size: 13px;
    color:black
}

.app-table tr:hover{
    background-color: rgba(182, 182, 182, 0.301);
    overflow: visible !important;
    height: 25px !important;
}
.app-table tr:nth-child(even) {background: rgba(204, 204, 204, 0.404)}
.app-table tr:nth-child(odd) {background: transparent}
.app-table tr.isActive{background: #54c97f60}
.app-table tr.isActive{background: #54c97f60}



.app-table tr.collapsing {
    width: 100%;
    background-color: transparent;
    padding: 4px;
    color: #ffdd00;
}

.app-table tr.collapsing:hover{
    cursor: pointer;
}

.app-table tr.collapsing .panel-collapse {
    width: 100%;
    max-height: 500px;
    overflow: hidden;
    transition: max-height .5s ease;
}

.app-table tr.collapsing .panel-close {
    max-height: 0;
}

.app-table tr.clickable:hover{
    cursor: pointer;
}

tr.expandable > td {
    padding: 8px;
    background-color: white;
    box-shadow: rgba(104, 104, 104, 0.541) 0px 30px 60px -20px inset, rgba(95, 95, 95, 0.822) 0px 18px 36px -18px inset;
}
  
tr.expandable > td > .inner {
    margin: 15px;
    overflow: hidden;
}