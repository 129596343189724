@font-face {
    font-family: vazir;
    src: url('../fonts/myVazir/Vazir-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

*{
     font-family: 'vazir';   
}

body{
     color: black;
}

span{
     color: white;
}

h5{
     color:black
}
a {
     color: #ffdd00;
     font-weight: bold;
     text-decoration: none;
     background-color: transparent;
}
a.dark {
     color: whitesmoke;
     font-weight: bold;
     text-decoration: none;
     background-color: transparent;
}

a:hover {
     color: #ffdd00;
     text-shadow: 0 0 10px #ffdd00;
     // background-color: black;
     // border-radius: 8px;
     // padding-left: 4px;
     // padding-right: 4px;
}

a.dark:hover {
     color: #ffdd00;
}

/* legned of fieldset */
legend {
     display: block;
     width: auto;
     padding: 0;
     margin-bottom: .5rem;
     font-size: 1.5rem;
     line-height: inherit;
     color: inherit;
     white-space: normal;
}
/* legned of fieldset */


.bg_app_primary{
     background-color: #ffdd00;
}

.container_app{
     width: 100%;
     // height: calc(100vh - 70px);
     height: 100vh;
     overflow-y: auto;
     overflow-x: hidden;
     transition: .4s;
}

// svg{
//      color: #ffdd00 !important;
// }

.container_app.dark{
     background-color: #2f3640;
     color: white;
     transition: .4s;
}

.grid-container{
     display: grid;
     // grid-template-columns: auto auto;
     grid-template-columns: 1fr 1fr;
     // grid-gap: 0px;
     grid-template-columns: repeat(auto-fit);
}

/* input checked */
.form-check-input:checked {
     background-color: #ffdd00;
     border-color: #ffdd00;
 }
/* input checked */


// form control 
.form-control.none-border {
     display: block;
     width: 100%;
     padding: .375rem .75rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #111;
     background-color: white;
     background-clip: padding-box;
     border: none !important;
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     border-radius: .25rem;
     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 }

 .form-control.none-border.dark{
     color: whitesmoke;
     background-color: #2f3640 !important;
 }

 .form-control:disabled, .form-control[readonly] {
     color: rgb(161, 161, 161);
     opacity: 1;
 }

 .form-control.dark:disabled, .form-control[readonly] {
     background-color: #2f3640;
     color: rgb(161, 161, 161);
     opacity: 1;
 }
 .form-control.dark:enabled, .form-control[readonly] {
     background-color: #2f3640;
     color: whitesmoke;
     opacity: 1;
 }
// form control

// react select
.css-yk16xz-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-color: transparent;
    border-radius: 0px !important;
    border-style: none;
    border-width: 0px !important;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: none !important;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    margin: 0px !important;
}
// react select


.home__icon g {
     fill: #FFFFFF;
}

.home__icon path {
     stroke: #e5e5e5;
     stroke-width: 10px;
}

.bg-transparent{
     background-color: #c0c0c065;
}
