.bg_card{
    display: flex;
    justify-content: start;
    flex-direction: column;
    height: max-content;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    border-radius: 4px;
    box-shadow: rgba(0,0,0, 0.3) 0px 1px 2px 0px, rgba(0,0,0, 0.15) 0px 1px 3px 1px;
    /* background-color: black; */
}

.bg_card.hover:hover{
    cursor: pointer;
}